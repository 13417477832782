import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { setNavigate } from './utils/navigation';
import { useSelector, useDispatch } from 'react-redux';
import { setDisplay } from './store/slices/authSlice';
import 'bootstrap/dist/css/bootstrap.min.css';


const TicketDetail = React.lazy(() => import('./containers/TicketDetailContainer'));
const ResetPass = React.lazy(() => import('./containers/ResetPasswordContainer'));
const Accueil = React.lazy(() => import('./containers/AccueilContainer'));
const TicketSetUp = React.lazy(() => import('./containers/TicketSetUpContainer'));
const TicketsListe = React.lazy(() => import('./containers/TicketListContainer'));
const ChantierList = React.lazy(() => import('./containers/ChantierListContainer'));
const ChantierDetail = React.lazy(() => import('./containers/ChantierDetailContainer'));
const LoginPage = React.lazy(() => import('./containers/LoginContainer'));
const UserDetail = React.lazy(() => import('./containers/UserDetailContainer'));
const UsersTablePage = React.lazy(() => import('./containers/UsersListContainer'));
const ClientListe = React.lazy(() => import('./containers/ClientsListContainer'));
const ClientDetail = React.lazy(() => import('./containers/ClientDetailContainer'));
const BackOffice = React.lazy(() => import('./containers/BackOfficeContainer'));

import WPFormSuccess from './pages/WpFormSuccess';

import NavigationMenu from './components/navigation/NavMenu';
import Logout from './components/auth/Logout';
import PrivateRoute from './components/navigation/PrivateRoute';
import LoadingSpinner from './components/shared/LoadingSpinner';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const display = useSelector((state) => state.auth.display); // Accéder à la valeur de display
  const navigate = useNavigate();
  const dispatch = useDispatch();
  setNavigate(navigate)

  const determineDisplayType = (width) => (width <= 768 ? 'mobile' : 'pc');

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const displayType = determineDisplayType(currentWidth);
      dispatch(setDisplay(displayType));
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <>
      {isAuthenticated && <NavigationMenu />}
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/wpFormSuccess/:TIK_num" element={<WPFormSuccess />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/accueil" element={<PrivateRoute><Accueil /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><UsersTablePage /></PrivateRoute>} />
          <Route path="/user/:id" element={<PrivateRoute><UserDetail /></PrivateRoute>} />
          <Route path="/tickets" element={<PrivateRoute><TicketsListe /></PrivateRoute>} />
          <Route path="/tickets/:id" element={<PrivateRoute><TicketDetail /></PrivateRoute>} />
          <Route path="/chantiers/" element={<PrivateRoute><ChantierList /></PrivateRoute>} />
          <Route path="/chantiers/:id" element={<PrivateRoute><ChantierDetail /></PrivateRoute>} />
          <Route path="/ticketSetup/:id" element={<PrivateRoute><TicketSetUp /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute><ClientListe /></PrivateRoute>} />
          <Route path="/client/:id" element={<PrivateRoute><ClientDetail /></PrivateRoute>} />
          <Route path="/backOffice" element={<PrivateRoute><BackOffice /></PrivateRoute>} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/resetPass/:token" element={<ResetPass />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;